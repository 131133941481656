<template>
  <view-item title="维修统计报表">
    <template #operation>
      <en-dropdown v-if="tabs.active === 'statistics'" @command="operation.export.command">
        <en-button type="primary">
          <span class="mr-1">导出</span>

          <en-icon> <arrow-down></arrow-down> </en-icon>
        </en-button>
        <template #dropdown>
          <en-dropdown-item command="branch">按门店导出</en-dropdown-item>
          <en-dropdown-item command="default">导出 </en-dropdown-item>
        </template>
      </en-dropdown>

      <button-ajax v-else :method="operation.export.click">导出</button-ajax>
    </template>

    <en-card body-class="h-full" class="h-full">
      <flex-box>
        <template #default="{ height }">
          <en-tabs v-model="tabs.active">
            <en-tab-pane label="营业统计" name="statistics" :style="{ height: height - 55 + 'px', overflow: 'auto' }">
              <form-query :method="statistics.table[statistics.mode]" :model="statistics.form.data">
                <en-form-item label="状态">
                  <en-checkbox v-model="statistics.mode" true-label="daily" false-label="daily">按日统计</en-checkbox>
                  <en-checkbox v-model="statistics.mode" true-label="monthly" false-label="monthly">按月统计</en-checkbox>
                </en-form-item>
                <en-form-item label="业务类型">
                  <select-maintain
                    v-model="statistics.form.data.serviceCategoryName"
                    :ajax="{
                      action: 'GET /enocloud/common/service/category',
                      params: (params) => {
                        params.payload = {}
                      }
                    }"
                    :props="{ label: 'name', value: 'name' }"
                    clearable
                    multiple
                  ></select-maintain>
                </en-form-item>

                <en-form-item label="工单状态">
                  <select-maintain
                    v-model="statistics.form.data.status"
                    :ajax="{
                      action: 'GET /enocloud/common/lookup/:lookupType',
                      params: (params) => {
                        params.paths = ['SVCSTAT']
                      }
                    }"
                    :props="{ label: 'message', value: 'code' }"
                  ></select-maintain>
                </en-form-item>
                <en-form-item label="结算日期">
                  <en-date-picker
                    v-if="statistics.mode === 'daily'"
                    v-model:start="statistics.form.data.settlementStartDate"
                    v-model:end="statistics.form.data.settlementEndDate"
                    type="daterange"
                    value-format="YYYY-MM-DD"
                  ></en-date-picker>
                  <en-date-picker
                    v-else
                    v-model:start="statistics.form.data.settlementStartMonth"
                    v-model:end="statistics.form.data.settlementEndMonth"
                    type="monthrange"
                    value-format="YYYY-MM"
                  ></en-date-picker>
                </en-form-item>

                <en-form-item label="结清日期">
                  <en-date-picker
                    v-if="statistics.mode === 'daily'"
                    v-model:start="statistics.form.data.settleStartDate"
                    v-model:end="statistics.form.data.settleEndDate"
                    type="daterange"
                    value-format="YYYY-MM-DD"
                  ></en-date-picker>
                  <en-date-picker
                    v-else
                    v-model:start="statistics.form.data.settleStartMonth"
                    v-model:end="statistics.form.data.settleEndMonth"
                    type="monthrange"
                    value-format="YYYY-MM"
                  ></en-date-picker>
                </en-form-item>

                <en-form-item label="进厂日期">
                  <en-date-picker
                    v-if="statistics.mode === 'daily'"
                    v-model:start="statistics.form.data.enterStartDate"
                    v-model:end="statistics.form.data.enterEndDate"
                    type="daterange"
                    value-format="YYYY-MM-DD"
                  ></en-date-picker>
                  <en-date-picker
                    v-else
                    v-model:start="statistics.form.data.enterStartMonth"
                    v-model:end="statistics.form.data.enterEndMonth"
                    type="monthrange"
                    value-format="YYYY-MM"
                  ></en-date-picker>
                </en-form-item>

                <en-form-item label="客户分类">
                  <select-maintain
                    v-model="statistics.form.data.customerCategoryId"
                    :ajax="{
                      action: 'GET /enocloud/common/customer/category',
                      params: (params, value) => (params.payload = { name: value })
                    }"
                    :props="{ label: 'name', value: '' }"
                    value-key="id"
                    class="w-full"
                    remote
                    clearable
                    multiple
                  ></select-maintain>
                </en-form-item>
                <en-form-item label="结算状态">
                  <select-maintain
                    v-model="statistics.form.data.settlementProgress"
                    :ajax="{
                      action: 'GET /enocloud/common/lookup/:lookupType',
                      params: (params) => {
                        params.paths = ['STLMTPRG']
                      }
                    }"
                    :props="{ label: 'message', value: 'code' }"
                    :disabled="statistics.form.data.status === 'RP'"
                    clearable
                  ></select-maintain>
                </en-form-item>
              </form-query>

              <flex-box>
                <template #default="{ height }">
                  <table-dynamic
                    v-if="statistics.mode === 'daily'"
                    code="SVCSTATFLD"
                    :height="height"
                    :data="statistics.table.data"
                    :loading="statistics.table.loading"
                    :paging="statistics.table.paging"
                    :summary="statistics.table.summary.data"
                    :method="statistics.table.daily"
                    pagination
                    show-summary
                  >
                    <template #INDEX="{ $index }">
                      {{ $index + 1 }}
                    </template>
                  </table-dynamic>

                  <table-dynamic
                    v-if="statistics.mode === 'monthly'"
                    code="SVCSTATFLD"
                    :height="height"
                    :data="statistics.table.data"
                    :loading="statistics.table.loading"
                    :paging="statistics.table.paging"
                    :summary="statistics.table.summary.data"
                    :method="statistics.table.monthly"
                    pagination
                    show-summary
                  >
                    <template #INDEX="{ $index }">
                      {{ $index + 1 }}
                    </template>
                  </table-dynamic>
                </template>
              </flex-box>
            </en-tab-pane>
            <en-tab-pane label="客户维修" name="customer" :style="{ height: height - 55 + 'px', overflow: 'auto' }">
              <form-query :method="customer.table.get" :model="customer.form.data">
                <en-form-item label="业务类型">
                  <select-maintain
                    v-model="customer.form.data.serviceCategoryName"
                    :ajax="{
                      action: 'GET /enocloud/common/service/category',
                      params: (params) => {
                        params.payload = {}
                      }
                    }"
                    :props="{ label: 'name', value: 'name' }"
                    clearable
                    multiple
                  ></select-maintain>
                </en-form-item>
                <en-form-item label="工单状态">
                  <select-maintain
                    v-model="customer.form.data.status"
                    :ajax="{
                      action: 'GET /enocloud/common/lookup/:lookupType',
                      params: (params) => {
                        params.paths = ['SVCSTAT']
                      }
                    }"
                    :props="{ label: 'message', value: 'code' }"
                  ></select-maintain>
                </en-form-item>

                <en-form-item label="结算日期">
                  <en-date-picker
                    v-model:start="customer.form.data.settlementStartDate"
                    v-model:end="customer.form.data.settlementEndDate"
                    type="daterange"
                    value-format="YYYY-MM-DD"
                    multiple
                  ></en-date-picker>
                </en-form-item>
                <en-form-item label="结清日期">
                  <en-date-picker
                    clearable
                    v-model:start="customer.form.data.settleStartDate"
                    v-model:end="customer.form.data.settleEndDate"
                    type="daterange"
                    value-format="YYYY-MM-DD"
                  ></en-date-picker>
                </en-form-item>
                <en-form-item label="进厂日期">
                  <en-date-picker
                    v-model:end="customer.form.data.enterEndDate"
                    v-model:start="customer.form.data.enterStartDate"
                    type="daterange"
                  ></en-date-picker>
                </en-form-item>
                <en-form-item label="客户分类">
                  <select-maintain
                    v-model="customer.form.data.customerCategoryId"
                    :ajax="{
                      action: 'GET /enocloud/common/customer/category',
                      params: (params, value) => {
                        params.payload = { name: value }
                      }
                    }"
                    :props="{ label: 'name', value: 'id' }"
                    clearable
                    multiple
                  ></select-maintain>
                </en-form-item>
                <en-form-item label="结算状态">
                  <select-maintain
                    v-model="customer.form.data.settlementProgress"
                    :ajax="{
                      action: 'GET /enocloud/common/lookup/:lookupType',
                      params: (params) => {
                        params.paths = ['STLMTPRG']
                      }
                    }"
                    :props="{ label: 'message', value: 'code' }"
                    :disabled="customer.form.data.status === 'RP'"
                    clearable
                  ></select-maintain>
                </en-form-item>
              </form-query>

              <flex-box>
                <template #default="{ height }">
                  <table-dynamic
                    code="CUSSVCFLD"
                    :height="height"
                    :data="customer.table.data"
                    :loading="customer.table.loading"
                    :paging="customer.table.paging"
                    :summary="customer.table.summary.data"
                    :method="customer.table.get"
                    pagination
                    show-summary
                    :config="customer.table.config"
                  >
                    <template #INDEX="{ $index }">
                      {{ $index + 1 }}
                    </template>
                  </table-dynamic>
                </template>
              </flex-box>
            </en-tab-pane>
            <en-tab-pane label="维修类别" name="category" :style="{ height: height - 55 + 'px', overflow: 'auto' }">
              <form-query :method="category.table.get" :model="category.form.data">
                <en-form-item label="业务类型">
                  <select-maintain
                    v-model="category.form.data.serviceCategoryName"
                    :ajax="{
                      action: 'GET /enocloud/common/service/category',
                      params: (params, value) => (params.payload = { name: value })
                    }"
                    :props="{ label: 'name', value: 'name' }"
                    clearable
                    multiple
                  ></select-maintain>
                </en-form-item>
                <en-form-item label="工单状态">
                  <select-maintain
                    v-model="category.form.data.status"
                    :ajax="{
                      action: 'GET /enocloud/common/lookup/:lookupType',
                      params: (params) => {
                        params.paths = ['SVCSTAT']
                      }
                    }"
                    :props="{ label: 'message', value: 'code' }"
                  ></select-maintain>
                </en-form-item>
                <en-form-item label="结算日期">
                  <en-date-picker
                    :disabled="category.form.data.status === 'RP'"
                    v-model:start="category.form.data.settlementStartDate"
                    v-model:end="category.form.data.settlementEndDate"
                    type="daterange"
                    value-format="YYYY-MM-DD"
                    multiple
                  ></en-date-picker>
                </en-form-item>
                <en-form-item label="结清日期">
                  <en-date-picker
                    :disabled="category.form.data.status === 'RP'"
                    clearable
                    v-model:start="category.form.data.settleStartDate"
                    v-model:end="category.form.data.settleEndDate"
                    type="daterange"
                    value-format="YYYY-MM-DD"
                    multiple
                  ></en-date-picker>
                </en-form-item>
                <en-form-item label="进厂日期">
                  <en-date-picker
                    v-model:end="category.form.data.enterEndDate"
                    v-model:start="category.form.data.enterStartDate"
                    type="daterange"
                    multiple
                  ></en-date-picker>
                </en-form-item>
                <en-form-item label="客户分类">
                  <select-maintain
                    v-model="category.form.data.customerCategoryId"
                    :ajax="{
                      action: 'GET /enocloud/common/customer/category',
                      params: (params, value) => {
                        params.payload = { name: value }
                      }
                    }"
                    :props="{ label: 'name', value: 'id' }"
                    clearable
                    multiple
                  ></select-maintain>
                </en-form-item>
                <en-form-item label="结算状态">
                  <select-maintain
                    v-model="category.form.data.settlementProgress"
                    :ajax="{
                      action: 'GET /enocloud/common/lookup/:lookupType',
                      params: (params) => {
                        params.paths = ['STLMTPRG']
                      }
                    }"
                    :props="{ label: 'message', value: 'code' }"
                    :disabled="category.form.data.status === 'RP'"
                    clearable
                  ></select-maintain>
                </en-form-item>
              </form-query>

              <flex-box>
                <template #default="{ height }">
                  <table-dynamic
                    code="SVSCATSTM"
                    :height="height"
                    :data="category.table.data"
                    :loading="category.table.loading"
                    :paging="category.table.paging"
                    :summary="category.table.summary.data"
                    :method="category.table.get"
                    pagination
                    show-summary
                  >
                    <template #INDEX="{ $index }">
                      {{ $index + 1 }}
                    </template>
                  </table-dynamic>
                </template>
              </flex-box>
            </en-tab-pane>
            <en-tab-pane label="维修车品牌" name="vehicle" :style="{ height: height - 55 + 'px', overflow: 'auto' }">
              <form-query :method="vehicle.table.get" :model="vehicle.form.data">
                <en-form-item label="业务类型">
                  <select-maintain
                    v-model="vehicle.form.data.serviceCategoryName"
                    :ajax="{
                      action: 'GET /enocloud/common/service/category',
                      params: (params) => {
                        params.payload = {}
                      }
                    }"
                    :props="{ label: 'name', value: 'name' }"
                    clearable
                    multiple
                  ></select-maintain>
                </en-form-item>
                <en-form-item label="工单状态">
                  <select-maintain
                    v-model="vehicle.form.data.status"
                    :ajax="{
                      action: 'GET /enocloud/common/lookup/:lookupType',
                      params: (params) => {
                        params.paths = ['SVCSTAT']
                      }
                    }"
                    :props="{ label: 'message', value: 'code' }"
                  ></select-maintain>
                </en-form-item>
                <en-form-item label="结算日期">
                  <en-date-picker
                    :disabled="vehicle.form.data.status === 'RP'"
                    v-model:start="vehicle.form.data.settlementStartDate"
                    v-model:end="vehicle.form.data.settlementEndDate"
                    type="daterange"
                    value-format="YYYY-MM-DD"
                    multiple
                  ></en-date-picker>
                </en-form-item>
                <en-form-item label="结清日期">
                  <en-date-picker
                    :disabled="vehicle.form.data.status === 'RP'"
                    clearable
                    v-model:start="vehicle.form.data.settleStartDate"
                    v-model:end="vehicle.form.data.settleEndDate"
                    type="daterange"
                    value-format="YYYY-MM-DD"
                    multiple
                  ></en-date-picker>
                </en-form-item>
                <en-form-item label="进厂日期">
                  <en-date-picker
                    v-model:end="vehicle.form.data.enterEndDate"
                    v-model:start="vehicle.form.data.enterStartDate"
                    type="daterange"
                    multiple
                  ></en-date-picker>
                </en-form-item>
                <en-form-item label="客户分类">
                  <select-maintain
                    v-model="vehicle.form.data.customerCategoryId"
                    :ajax="{
                      action: 'GET /enocloud/common/customer/category',
                      params: (params, value) => {
                        params.payload = { name: value }
                      }
                    }"
                    :props="{ label: 'name', value: 'id' }"
                    clearable
                    multiple
                  ></select-maintain>
                </en-form-item>
                <en-form-item label="结算状态">
                  <select-maintain
                    v-model="vehicle.form.data.settlementProgress"
                    :ajax="{
                      action: 'GET /enocloud/common/lookup/:lookupType',
                      params: (params) => {
                        params.paths = ['STLMTPRG']
                      }
                    }"
                    :props="{ label: 'message', value: 'code' }"
                    :disabled="vehicle.form.data.status === 'RP'"
                    clearable
                  ></select-maintain>
                </en-form-item>
                <en-form-item label="车品牌">
                  <en-input v-model="vehicle.form.data.vehicleSpec"></en-input>
                </en-form-item>
              </form-query>

              <flex-box>
                <template #default="{ height }">
                  <table-dynamic
                    code="MNEVLSTAFLD"
                    :height="height"
                    :data="vehicle.table.data"
                    :loading="vehicle.table.loading"
                    :paging="vehicle.table.paging"
                    :summary="vehicle.table.summary.data"
                    :method="vehicle.table.get"
                    pagination
                    show-summary
                  >
                    <template #INDEX="{ $index }">
                      {{ $index + 1 }}
                    </template>
                  </table-dynamic>
                </template>
              </flex-box>
            </en-tab-pane>
          </en-tabs>
        </template>
      </flex-box>
    </en-card>
  </view-item>
</template>

<script lang="ts">
import dayjs from 'dayjs'
import { omit, pick } from 'lodash-es'
import { ArrowDown } from '@element-plus/icons-vue'

export default factory({
  components: { ArrowDown },

  config: {
    children: {
      operation: {
        children: {
          export: {
            ajax: {
              daily: {
                action: 'GET /enocloud/statement/export/service/daily',
                params(params) {
                  params.payload = pick(
                    this.statistics.form.data,
                    'serviceCategoryName',
             
                    'customerCategoryId',
                    'settlementProgress',
                    'enterStartDate',
                    'enterEndDate',

                    'enterEndDate',
                    'settleEndDate',
                    'settleStartDate',

                    'mode',
                    'status',
                    'groupByBranch'
                  )
                }
              },
              monthly: {
                action: 'GET /enocloud/statement/export/service/monthly',
                params(params) {
                  params.payload = pick(
                    this.statistics.form.data,
                    'serviceCategoryName',

                    'customerCategoryId',
                    'settlementProgress',

                    'enterStartMonth',

                    'settleStartMonth',
                    'settleEndMonth',
                    'settlementStartDate',
                    'settlementEndDate',
                    'settleStartDate',
                    'settleEndDate',
                    'enterStartDate',
                    'enterEndDate',
                    'mode',
                    'status',
                    'groupByBranch'
                  )
                }
              },
              defaultdaily: {
                action: 'GET /enocloud/statement/export/service/daily',
                params(params) {
                  params.payload = pick(
                    this.statistics.form.data,
                    'serviceCategoryName',
                    'settlementStartDate',
                    'settlementEndDate',
                    'customerCategoryId',
                    'settlementProgress',
                    'enterStartDate',
                    'enterEndDate',

                    'enterEndDate',
                    'settleEndDate',
                    'settleStartDate',

                    'mode',
                    'status'
                  )
                }
              },
              defaultmonthly: {
                action: 'GET /enocloud/statement/export/service/monthly',
                params(params) {
                  params.payload = pick(
                    this.statistics.form.data,
                    'serviceCategoryName',

                    'customerCategoryId',
                    'settlementProgress',

                    'enterStartMonth',

                    'settleStartMonth',
                    'settleEndMonth',

                    'mode',
                    'status'
                  )
                }
              },
              customer: {
                action: 'GET /enocloud/statement/export/customer/service',
                params(params) {
                  params.payload = this.customer.form.data
                }
              },
              category: {
                action: 'GET /enocloud/statement/export/maintenance/category',
                params(params) {
                  params.payload = this.category.form.data
                }
              },
              vehicle: {
                action: 'GET /enocloud/statement/export/maintenance/vehicle',
                params(params) {
                  params.payload = this.vehicle.form.data
                }
              }
            },
            async command(option: string) {
              switch (option) {
                case 'branch':
                  if (this.statistics.mode === 'daily') await this.operation.export.daily()
                  else await this.operation.export.monthly()
                  this.store.openDownload()
                  break
                case 'default':
                  if (this.statistics.mode === 'daily') await this.operation.export.defaultdaily()
                  else await this.operation.export.defaultmonthly()
                  this.store.openDownload()
                  break
              }
            },
            async click() {
              if (['customer', 'category', 'vehicle'].includes(this.tabs.active)) {
                await this.operation.export[this.tabs.active as 'customer' | 'category' | 'vehicle']()
                this.store.openDownload()
              }
            }
          }
        }
      },
      tabs: {
        active: 'statistics'
      },
      statistics: {
        mode: 'daily' as 'daily' | 'monthly',
        children: {
          form: {
            data: {
              serviceCategoryName: '',
              status: 'PD',
              settlementStartDate: dayjs().startOf('month').format('YYYY-MM-DD'),
              settlementEndDate: dayjs().format('YYYY-MM-DD'),
              settlementStartMonth: dayjs().format('YYYY-MM'),
              settlementEndMonth: dayjs().format('YYYY-MM'),
              customerCategoryId: '',
              settlementProgress: '',
              enterEndMonth: '',
              enterStartMonth: '',
              enterStartDate: '',
              enterEndDate: '',
              settleEndDate: '',
              settleStartDate: '',
              settleStartMonth: dayjs().format('YYYY-MM'),
              settleEndMonth: dayjs().format('YYYY-MM'),
              checked: true,
              mode: '',
              groupByBranch: true
            }
          },
          table: {
            ajax: {
              daily: {
                action: 'GET /enocloud/statement/service/daily',
                summary: 'GET /enocloud/statement/service/summary',
                data: 'array',
                loading: true,
                pagination: true,
                params(params) {
                  params.paths = ['daily']
                  params.payload = pick(
                    this.statistics.form.data,
                    'serviceCategoryName',
                    'settlementStartDate',
                    'settlementEndDate',
                    'customerCategoryId',
                    'settlementProgress',
                    'enterStartDate',
                    'enterEndDate',
                    'enterStartMonth',
                    'enterEndDate',
                    'settleEndDate',
                    'settleStartDate',
                    'mode',
                    'status',
         
                  )
                }
              },
              monthly: {
                action: 'GET /enocloud/statement/service/monthly',
                summary: 'GET /enocloud/statement/service/summary',
                data: 'array',
                loading: true,
                pagination: true,
                params(params) {
                  params.paths = ['monthly']
                  params.payload = pick(
                    this.statistics.form.data,
                    'settlementStartMonth',
                    'settlementEndMonth',
                    'serviceCategoryName',
                
                    'customerCategoryId',
                    'settlementProgress',
                    'enterStartDate',
                    'enterEndDate',
                    'enterStartMonth',
                    'enterEndDate',
                    'mode',
                    'status'
                  )
                }
              }
            }
          }
        }
      },
      customer: {
        children: {
          form: {
            is: 'form',
            data: {
              serviceCategoryName: '',
              status: 'PD',
              settlementStartDate: dayjs().startOf('month').format('YYYY-MM-DD'),
              settlementEndDate: dayjs().format('YYYY-MM-DD'),
              settleStartDate: '',
              settleEndDate: '',
              enterStartDate: dayjs().startOf('month').format('YYYY-MM-DD'),
              enterEndDate: dayjs().format('YYYY-MM-DD'),
              customerCategoryId: '',
              settlementProgress: ''
            }
          },
          table: {
            ajax: {
              get: {
                action: 'GET /enocloud/statement/customer/service',
                summary: 'GET /enocloud/statement/customer/service/summary',
                data: 'array',
                loading: true,
                pagination: true,
                params(params) {
                  params.payload = this.customer.form.data
                }
              }
            },
            config: {
              CELLPHONE: { header: { filter: { type: 'text', field: 'customerCellphone' } } },
              CUSTOMER_NAME: { header: { filter: { type: 'text', field: 'customerName' } } }
            }
          }
        }
      },
      category: {
        children: {
          form: {
            is: 'form',
            data: {
              serviceCategoryName: '',
              status: 'PD',
              settlementStartDate: dayjs().startOf('month').format('YYYY-MM-DD'),
              settlementEndDate: dayjs().format('YYYY-MM-DD'),
              settleStartDate: '',
              settleEndDate: '',
              enterStartDate: '',
              enterEndDate: '',
              customerCategoryId: '',
              settlementProgress: ''
            }
          },
          table: {
            ajax: {
              get: {
                action: 'GET /enocloud/statement/maintenance/category',
                summary: 'GET /enocloud/statement/maintenance/category/summary',
                data: 'array',
                loading: true,
                pagination: true,
                params(params) {
                  params.payload = this.category.form.data
                }
              }
            }
          }
        }
      },
      vehicle: {
        children: {
          form: {
            is: 'form',
            data: {
              serviceCategoryName: '',
              status: 'PD',
              settlementStartDate: dayjs().startOf('month').format('YYYY-MM-DD'),
              settlementEndDate: dayjs().format('YYYY-MM-DD'),
              settleStartDate: '',
              settleEndDate: '',
              enterStartDate: '',
              enterEndDate: '',
              customerCategoryId: '',
              settlementProgress: '',
              vehicleSpec: ''
            }
          },
          table: {
            ajax: {
              get: {
                action: 'GET /enocloud/statement/maintenance/vehicle',
                summary: 'GET /enocloud/statement/maintenance/vehicle/summary',
                data: 'array',
                loading: true,
                pagination: true,
                params(params) {
                  params.payload = this.vehicle.form.data
                }
              }
            }
          }
        }
      }
    }
  },

})
</script>
